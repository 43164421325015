import { CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TopicSubtopicContentSelector from '../../components/shared/TopicSubtopicContentSelector';
import { Topic } from '../../interfaces/module';
import { Question, QuestionRequest } from '../../interfaces/Question';
import { useModuleQuestions } from '../../services/questions';
import QuestionCard from './QuestionCard';
import { useCallback, useEffect } from 'react';



const Container = styled.div`
    background: #F8F9FA;
    min-height: 100vh;
    padding: 2rem;
`;



const Dropdown = styled.select`
  padding: 0.5rem;
  opacity: 60%;
  margin-right: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.8rem;
  background: white;
  &:focus {
    outline: none;v
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;




const QuestionCardPage = () => {

    const { mutate: getQuestions, isPending, data, error } = useModuleQuestions();


    const { topic, subTopic, contentName, origClassId,isExam } = useParams()



    const location = useLocation();
    const { topics }: { topics: Topic[], originalClassId: string } = location.state || [];


    const triggerApi = useCallback(async ({ topic, subTopic, content, originalClassId }: { topic: string, subTopic: string, content: string, originalClassId: string,isExam:string }) => {

        const userIdentifier = Cookies.get("access_token");
        const request: QuestionRequest = {
            contentName: content,
            identifier: userIdentifier!,
            subtopic: subTopic,
            topic: topic,
            originalClassId: originalClassId,
            isExam:isExam||"NOT_EXAM"
        }
        console.log(request)
        getQuestions(request);
    }, [getQuestions])

    useEffect(() => {
        if (contentName && subTopic && topic)
            triggerApi({ topic: topic!, subTopic: subTopic!, content: contentName!, originalClassId: origClassId!,isExam:isExam||"NOT_EXAM" })

    }, [contentName, origClassId, subTopic, topic, triggerApi])

    return (
        <Container>
            {!(topic || subTopic || contentName) && <TopicSubtopicContentSelector topicList={topics} onSelectionChange={triggerApi} originalClassId={location.state?.originalClassId}></TopicSubtopicContentSelector>}
            {isPending && <Container><CircularProgress /></Container>}
            {data && data.map((question: Question, idx:number) => {
                console.log('question ==> ', question)
                return <QuestionCard key={idx} questionData={question} originalClassId={location.state?.originalClassId} isExam={isExam!=undefined}></QuestionCard>
            })}
            {error && <Container>Error while loading the questions for the selected topic.</Container>}

        </Container>
    );
};

export default QuestionCardPage;
