import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Subtopic, Topic } from '../../interfaces/module';


const Mainheading = styled.p`
    margin: 0;
    font-size: 0.8rem;
    padding: 0.2rem;
    opacity: 40%;
    margin-right: 1rem;
`;


const Dropdown = styled.select`
  @media (min-width: 600px) {
    flex: 1 1 calc(33.333% - 16px); /* One-third width on medium screens */
  }
  padding: 0.5rem;
  min-width:5rem;
  max-width:20rem;
  opacity: 60%;
  margin-right: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.8rem;
  background: white;
  &:focus {
    outline: none;v
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 1200px;
`;

const TopicDropDown = Dropdown;
const SubTopicDropDown = Dropdown;

const ContentDropDown = Dropdown;


const SearchSection = styled.section`
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;


interface Selector {
    topicList: Topic[];
    originalClassId: string;
    onSelectionChange: (
        { topic, subTopic, content, originalClassId,isExam }: { topic: string, subTopic: string, content: string, originalClassId: string,isExam:string }
    ) => {}
}
const TopicSubtopicContentSelector = (selector: Selector) => {


    const topicList = selector.topicList;

    const [topics, setTopics] = useState<string[]>([])
    const [subTopics, setSubTopics] = useState<Subtopic[]>([])
    const [contents, setContents] = useState<string[]>([])

    const [selectedTopic, setSelectedTopic] = useState<string>('')
    const [selectedSubTopic, setSelectedSubTopic] = useState<string>('')
    const [selectedContent, setSelectedContent] = useState<string>('')

    useEffect(() => {

        if (topicList && topicList.length > 0) {
            const topics = topicList.map((t: Topic) => t.topic);
            setTopics(topics)
            setSelectedSubTopic("")
            setSelectedContent("")
        }
    }, [topicList])

    //Change functions

    const updateSubTopicList = useCallback((selectedTopic: string) => {
        if (topicList && topicList.length > 0) {

            const subTopicList = topicList
                .filter((topic: Topic) => topic.topic === selectedTopic)
                .flatMap((topic: Topic) => topic.subtopics);
            setSubTopics(subTopicList)
            setSelectedContent("")
        }
    }, [topicList])


    const onTopicChange = (event: any) => {
        setSelectedTopic(event.target.value)
        setSelectedSubTopic("")
        setSelectedContent("")
        // setShowTopicConfirmModal(false)
    }

    const onSubTopicChange = (event: any) => {
        console.log('event.target.value', event.target.value)
        setSelectedSubTopic(event.target.value)
        updateContentList(event.target.value)
    }

    const onContentChange = (event: any) => {
        setSelectedContent(() => event.target.value)
        selector.onSelectionChange({ topic: selectedTopic, subTopic: selectedSubTopic, content: event.target.value, originalClassId: selector.originalClassId,isExam:"NOT_EXAM" })
    }

    const updateContentList = useCallback((selectedSubTopic: string) => {
        const contentList = subTopics
            .filter((subtopic: Subtopic) => subtopic.subtopic === selectedSubTopic)
            .flatMap((subtopic: Subtopic) => subtopic.content.map(content => content.contentName!));
        setContents(contentList)
    }, [subTopics])



    //On selection of a topic
    useEffect(() => {
        updateSubTopicList(selectedTopic)
        setSelectedSubTopic("")
        setSelectedContent("")
    }, [selectedTopic, updateSubTopicList])

    useEffect(() => {
        updateContentList(selectedSubTopic)
        setSelectedContent("")
    }, [selectedSubTopic, updateContentList])



    return (
        <SearchSection>
            <Container >
                <section>
                    <Mainheading>Topic</Mainheading>
                    <TopicDropDown onChange={onTopicChange} value={selectedTopic}>
                        <option value="" disabled>Topic</option>
                        {topics && topics.map((topic, index) =>
                            <option value={topic} key={index}>{topic}</option>
                        )}
                    </TopicDropDown>
                </section>
                <section>
                    <Mainheading>Sub Topic</Mainheading>

                    <SubTopicDropDown onChange={onSubTopicChange} value={selectedSubTopic}>
                        <option value="" disabled>Sub Topic</option>
                        {subTopics && subTopics.map((subTopic, index) =>
                            <option value={subTopic.subtopic} key={index}>{subTopic.subtopic}</option>
                        )}
                    </SubTopicDropDown>
                </section>
                <section>
                    <Mainheading>Content</Mainheading>

                    <ContentDropDown onChange={onContentChange} value={selectedContent}>
                        <option value="" disabled>Content Name</option>
                        {contents && contents.map((content, index) =>
                            <option key={index} value={content}>{content}</option>
                        )}
                    </ContentDropDown>
                </section>
            </Container>
            {/* <ConfirmModal
                handleCancel={() => setShowTopicConfirmModal(false)}
                handleConfirm={onTopicChange}
                message='This would clear the questions'
                isModalOpen={showTopicConfirmModal}
                title='Confirm Title Change'
            ></ConfirmModal> */}
        </SearchSection>
    );
};

export default TopicSubtopicContentSelector;
