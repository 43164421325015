import { ArrowBack, DeleteOutline, Draw } from '@mui/icons-material';
import { Textarea, Typography, Switch } from '@mui/joy';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import urlConfig from '../../../configs/urlConfig';
import { PictureData } from '../QuestionCard';
import {
  FeedbackModalOverlay,
  FeedbackModalTopBar,
  FeedbackModalWrapper,
  ModalTitle
} from '../QuestionCard.styles';
import 'katex/dist/katex.min.css';
// import { InlineMath, BlockMath } from 'react-katex';

const PictureAnswer = ({ handleCloseNeedHelpModal, qid, pictureData, setPictureData }: { handleCloseNeedHelpModal: () => void, qid: string, pictureData: PictureData[], setPictureData: React.Dispatch<React.SetStateAction<PictureData[]>> }) => {
  //urlConfig.apiUrl = 'http://192.168.0.31:8081';
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [reset, setReset] = useState(0);

  const [edit, setEdit] = useState(0);


  const fetchImage = async (qid: string) => {
    try {
      const response = await fetch(`${urlConfig.apiUrl}/getPictureImage?qid=${qid}`, {
        method: 'POST',
      });

      if (!response.ok) {
        setPictureData(prev => {
          prev = prev.filter((dataP, _) => dataP.id !== qid);
          return prev
        })
        setReset(prev => prev + 1)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
 
      // Get response as text (Base64 string)
      const base64String = await response.json();

      // Convert Base64 string to data URL
      const dataUrl = `data:image/jpeg;base64,${base64String[0]}`;
      setPictureData(prev => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i].id === qid) {
            prev[i].type=base64String[1];
            prev[i].pictureDataUrl = dataUrl
          }
        }
        return prev
      })
      setReset(prev => prev + 1)
      const canvas = document.createElement('canvas');
      canvas.width = 400;
      canvas.height = 400;

      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = dataUrl;
      img.onload = async () => {
        if (ctx) {
          const aspectRatioImage = img.width / img.height;
          const aspectRatioCanvas = canvas.width / canvas.height;
          let sx, sy, sWidth, sHeight;
          if (aspectRatioImage > aspectRatioCanvas) {
            sHeight = img.height;
            sWidth = sHeight * aspectRatioCanvas;
            sx = (img.width - sWidth) / 2;
            sy = 0;
          } else {
            sWidth = img.width;
            sHeight = sWidth / aspectRatioCanvas;
            sx = 0;
            sy = (img.height - sHeight) / 2;
          }
          ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);

          const resizedDataUrl = canvas.toDataURL('image/png');
          try {
            const response = await fetch(`${urlConfig.apiUrl}/readImageOut`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ image: resizedDataUrl,type:base64String[1] }),
            });

            if (!response.ok) {
              setPictureData(prev => {
                prev = prev.filter((dataP, _) => dataP.id !== qid);
                return prev
              })
              setReset(prev => prev + 1)
              throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const text = await response.json();
            setPictureData(prev => {
              for (let i = 0; i < prev.length; i++) {
                if (prev[i].id === qid) {
                  prev[i].readOut = text
                }
              }
              return prev
            })
            setReset(prev => prev + 1)
          } catch (err) {
            setPictureData(prev => {
              prev = prev.filter((dataP, _) => dataP.id !== qid);
              return prev
            })
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
      };
    } catch (err) {
      setPictureData(prev => {
        prev = prev.filter((dataP, _) => dataP.id !== qid);
        return prev
      })
      console.error('Error fetching image:', err);
    }
  };

  return (
    <FeedbackModalOverlay>

      <FeedbackModalWrapper style={{ alignItems: 'center', justifyContent: 'center', width: '100%', boxShadow: 'unset' }}>
        <FeedbackModalTopBar style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderBottom: '1px solid #ccc', backgroundColor: '#fff' }}>
          <ModalTitle style={{ fontSize: '32px', fontWeight: 600, lineHeight: '0rem' }}>Manage pictures related to your answer!</ModalTitle>
          <div onClick={handleCloseNeedHelpModal} style={{ backgroundColor: '#0D6EFD', paddingLeft: '16px', paddingRight: '16px', paddingTop: '12px', paddingBottom: '12px', borderRadius: '6px', display: 'flex', boxShadow: '0px 4px 4px 0px #00000040' }}>
            <ArrowBack fontSize='small' onClick={handleCloseNeedHelpModal} style={{
              color: '#fff', fontSize: '16px',
            }} />
          </div>

        </FeedbackModalTopBar>

        <div>
          {pictureData.map((data, index) => (
            <div key={index}  >

              <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>

                <div style={{ flex: 6 }}>
                  <Input variant='outlined' placeholder='Image title'
                    defaultValue={data.title}
                    style={{
                      height: '42px'
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPictureData(prev => {
                        for (let i = 0; i < prev.length; i++) {
                          if (prev[i].id === data.id) {
                            prev[i].title = value
                          }
                        }
                        return prev
                      })

                    }}
                  ></Input>

                </div>
                <div>
                  <Button
                    style={{
                      color: '#fff',
                      backgroundColor: '#0D6EFD',
                      borderRadius: '6px',
                      border: '1px solid #ccc',
                      height: '42px',
                      padding: '16px 32px 16px 32px',
                      gap: '8px',

                    }}
                    variant="solid"
                    onClick={() => {
                      setPictureData(prev => {
                        prev = prev.filter((dataP, _) => dataP.id !== data.id);
                        return prev
                      })
                      setReset(prev => prev + 1)
                    }}>Delete</Button>
                </div>
                <div >
                  <Button
                    style={{
                      backgroundColor: '#fff',
                      color: '#0D6EFD',
                      borderRadius: '6px',
                      border: '1px solid #ccc',
                      height: '42px',
                      padding: '16px 32px 16px 32px',
                      gap: '8px',
                    }}
                    variant="solid"
                    onClick={() => {

                    }}>Edit</Button>
                </div>

              </div>
              <div style={{ display: 'flex' }}>
                {data.pictureDataUrl ? (
                  <div style={{ display: 'flex', gap: '4rem', flex: 8, border: '1px solid #ccc', padding: '36px', borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>

                    <img
                      src={data.pictureDataUrl}
                      style={{ width: 'auto', height: '400px', objectFit: 'contain', flex: 1 }}
                      alt="Fetched content"
                    />

                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', flex: 1, width: '100%' }}>
                      {edit != 0 ? (
                        <> 
                          {edit == 1 ?
                            <Textarea disabled={!edit}
                              slotProps={{ textarea: { placeholder: 'Processing your image....' } }} defaultValue={data.readOut.text}
                              minRows={15} maxRows={20} onChange={(e) => data.readOut.text = e.target.value}
                              style={{ width: '100%', marginBottom: '1rem' }}></Textarea>
                            : <>
                              {data.readOut.latex.map((dataL, index) => (
                                <Textarea disabled={!edit}
                                  slotProps={{ textarea: { placeholder: 'Processing your image....' } }} defaultValue={dataL}
                                  minRows={15} maxRows={20} onChange={(e) => {
                                    setPictureData(prev => {
                                      for (let i = 0; i < prev.length; i++) {
                                        if (prev[i].id === data.id) {
                                          prev[i].readOut.latex[index] = e.target.value
                                        }
                                      }
                                      return prev
                                    })
                                  }}
                                  style={{ width: '100%', marginBottom: '1rem' }}></Textarea>
                              ))}
                            </>
                          }
                        </>
                      ) : (<>{data.readOut.text}
                        {data.readOut.latex.length > 0 && (data.readOut.latex.map((data, index) => (
                         <div></div>
                        )))}
                      </>)}

                      <div style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ backgroundColor: '#0D6EFD', padding: '12px 20px 12px 20px', display: 'flex', borderRadius: '6px' }}
                          onClick={() => setPictureData(prev => {
                            prev = prev.filter((dataP, _) => dataP.id !== data.id);
                            return prev
                          })}>
                          <DeleteOutline style={{ color: "#fff", fontSize: '14px' }} />
                        </div>
                        <div >
                          {edit == 0 ? <>
                            <Button
                              style={{
                                color: '#fff',
                                backgroundColor: '#0D6EFD',
                                borderRadius: '6px',
                                border: '1px solid #ccc',
                                height: '42px',
                                padding: '16px 32px 16px 32px',
                                gap: '8px',
                                width: '50%',
                              }}
                              variant="solid"
                              onClick={() => {

                                setEdit(1)
                              }}>{!edit ? <>Edit your message <Draw /></> : 'Done'}

                            </Button>
                            <Button
                              style={{
                                color: '#fff',
                                backgroundColor: '#0D6EFD',
                                borderRadius: '6px',
                                border: '1px solid #ccc',
                                height: '42px',
                                padding: '16px 32px 16px 32px',
                                gap: '8px',
                                width: '50%',

                              }}
                              variant="solid"
                              onClick={() => {

                                setEdit(2)
                              }}>{!edit ? <>Edit your maths <Draw /></> : 'Done'}

                            </Button>
                          </> : <Button onClick={() => setEdit(0)}>Close</Button>}

                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', gap: '4rem', flex: 10, border: '1px solid #ccc', padding: '36px', borderRadius: '14px', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{
                      flexDirection: "row",
                      display: 'flex',
                      gap: '4rem',
                    }}>
                      <QRCode
                        size={256}
                        style={{ height: 'auto', width: 'auto' }}
                        value={`${urlConfig.apiUrl}/addPictureAnswer?qid=${data.id}`}
                        viewBox="0 0 256 256"
                      />

                      <div style={{ display: 'flex', flexDirection: 'column', flex: 2, justifyContent: 'center' }}>
                        <span style={{ fontSize: '32px', fontWeight: 700 }}>
                          QR CODE
                        </span>
                        <span style={{ fontSize: '16px' }}>
                          Scan the QR code to upload the image that has been drawn on your workssheet!
                        </span>
                      </div>

                    </div>
                  </div>
                )}


              </div>
            </div>
          ))}
          <Button onClick={() => {
            console.log("Add new picture!");
            const myId = qid + Math.random().toString();
            setPictureData(prev => {
              prev.push({ id: myId, pictureDataUrl: "", readOut: { text: "", latex: [], image: "", pythonCode: "" }, title: "",type:"none" });
              console.log(prev);
              return prev;
            });
            setReset(reset + 1);
            fetchImage(myId);
          }} style={{ marginTop: '20px', marginBottom: "20px", alignSelf: "center" }}>Add new picture!</Button>

        </div>

      </FeedbackModalWrapper>
    </FeedbackModalOverlay >
  );
};

export default PictureAnswer;
