import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Module, Topic } from "../../interfaces/module";
import { useGetDisciplines } from "../../services/classes";
import "./styles.css";
import CalendarEvents from "./CalendarEvents";

// HERE'S THE CLASSES

const AllClasses = () => {
  const { data: classes, isLoading } = useGetDisciplines();
  const [isTeacher, setIsTeacher] = React.useState(false);
  const navigate = useNavigate();

  const showTopic = (
    topics: Topic[],
    className: string,
    originalClassId: string
  ) => {
    navigate("/Class/Topics/" + originalClassId, {
      state: {
        topics: topics,
        className: className,
        originalClassId: originalClassId,
      },
    });
  };

  const showAnalytics = (
    topics: Topic[],
    className: string,
    originalClassId: string
  ) => {
    navigate("/Class/Analytics/" + originalClassId, {
      state: {
        topics: topics,
        className: className,
        originalClassId: originalClassId,
      },
    });
  };


  const handleTryExamQuestions = (
    topics: Topic[],
    originalClassId: string
  ): void => {
    navigate("/Class/Questions", {
      state: {
        topics: topics,
        originalClassId: originalClassId,
      },
    });
  };
  React.useEffect(() => {
    if (!isLoading){
      if (classes && Array.isArray(classes.result)) {
      setIsTeacher(classes.numberOfQuestions != -1);
      }
    }
  },[isLoading])
  // Show a loading state until classes data is available
  if (isLoading) {
    return <div className="loading">Loading classes...</div>;
  }

  if (!classes?.result || classes.result.length === 0) {
    return <div className="no-data">No classes available</div>;
  }


  return (
    <div className="main-cont_clases">
      <div className="main_allclases">
        {!isTeacher? (
          <div className="top_advertise_main_allclases">
            <div className="cont_top_advertise_main_allclases">
              <h1>Discover, collect, and use helpful courses</h1>
              <p>
                Enter this creative world. Discover now the latest informative
                courses.
              </p>
              <div className="button_conts">
                <button>Discover More</button>
                <button className="non_selected">Hide Promotion</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="top_advertise_teacher_allclases">
            <div className="top_teacher_allclass">
              <div className="box_top_teacherallclasss">
                <div className="left_boxteacher">
                  <svg
                    width="53"
                    height="48"
                    viewBox="0 0 53 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46.0778 36.2008C44.5564 36.0008 43.0349 35.8008 41.2962 35.8008C35.8625 35.8008 30.6461 37.2008 26.0818 40.0008C21.5175 37.4008 16.3011 35.8008 10.8674 35.8008C9.34601 35.8008 7.60722 36.0008 6.08579 36.2008C4.99905 36.4008 4.12966 37.4008 4.347 38.6008C4.56435 39.8008 5.65109 40.4008 6.95518 40.2008C8.25927 40.0008 9.56336 39.8008 10.8674 39.8008C15.8664 39.8008 20.6481 41.2008 24.7777 44.0008C25.4298 44.6008 26.5165 44.6008 27.3859 44.0008C32.6022 40.6008 39.1227 39.2008 45.2084 40.2008C46.2952 40.4008 47.5993 39.6008 47.8166 38.6008C48.0339 37.4008 47.1646 36.4008 46.0778 36.2008ZM46.0778 4.20078C44.5564 4.00078 43.0349 3.80078 41.2962 3.80078C35.8625 3.80078 30.6461 5.20078 26.0818 8.00078C21.5175 5.20078 16.3011 3.80078 10.8674 3.80078C9.34601 3.80078 7.60722 4.00078 6.08579 4.20078C5.2164 4.20078 4.347 5.20078 4.347 6.00078V30.0008C4.347 31.2008 5.2164 32.0008 6.52048 32.0008C6.73783 32.0008 6.73783 32.0008 6.95518 32.0008C8.25927 31.8008 9.56336 31.6008 10.8674 31.6008C15.8664 31.6008 20.6481 33.0008 24.7777 35.8008C25.4298 36.4008 26.5165 36.4008 27.3859 35.8008C32.6022 32.4008 39.1227 31.0008 45.2084 32.0008C46.2952 32.2008 47.5993 31.4008 47.8166 30.4008C47.8166 30.2008 47.8166 30.2008 47.8166 30.0008V6.00078C47.8166 5.20078 46.9472 4.20078 46.0778 4.20078Z"
                      fill="white"
                    />
                  </svg>

                  <div className="text_leftboxteacher">
                    <h1>{classes.result.length}</h1>
                    <p>Total Courses</p>
                  </div>
                </div>
                <div className="circle_progressteacher"></div>
              </div>
              <div className="box_top_teacherallclasss">
                <div className="left_boxteacher">
                  <svg
                    width="38"
                    height="48"
                    viewBox="0 0 38 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M31.7396 4.76999H29.8649C29.1803 2.46002 26.8982 0.75 24.1758 0.75H13.841C11.1186 0.75 8.83653 2.46002 8.16814 4.76999H6.27726C3.03336 4.76999 0.392578 7.19999 0.392578 10.185V41.835C0.392594 44.82 3.03336 47.25 6.27726 47.25H31.7396C34.9835 47.25 37.6243 44.82 37.6243 41.835V10.185C37.6242 7.19999 34.9835 4.76999 31.7396 4.76999ZM10.4177 32.67H27.6154C28.9521 32.67 30.0606 33.69 30.0606 34.92C30.0606 36.165 28.9521 37.17 27.6154 37.17H10.4177C9.06478 37.17 7.97254 36.165 7.97254 34.92C7.97254 33.69 9.06478 32.67 10.4177 32.67ZM7.97254 26.97C7.97254 25.725 9.06478 24.72 10.4177 24.72H27.6154C28.9521 24.72 30.0606 25.725 30.0606 26.97C30.0606 28.2 28.9521 29.22 27.6154 29.22H10.4177C9.06478 29.22 7.97254 28.2 7.97254 26.97ZM27.6154 21.255H10.4177C9.06478 21.255 7.97254 20.25 7.97254 19.005C7.97254 17.76 9.06478 16.755 10.4177 16.755H27.6154C28.9521 16.755 30.0606 17.76 30.0606 19.005C30.0606 20.25 28.9521 21.255 27.6154 21.255ZM12.8629 6.16499C12.8629 5.65503 13.303 5.25 13.841 5.25H24.1758C24.7138 5.25 25.1703 5.65503 25.1703 6.16499V7.875C25.1703 8.38505 24.7138 8.78999 24.1758 8.78999H13.841C13.303 8.78999 12.8629 8.38505 12.8629 7.875V6.16499Z"
                      fill="white"
                    />
                  </svg>

                  <div className="text_leftboxteacher">
                    <h1>{
                  classes.result.map((data) => data.topics.length)
                  
                  }</h1>
                    <p>Course Content</p>
                  </div>
                </div>
                <div className="circle_progressteacher"></div>
              </div>
              <div className="box_top_teacherallclasss">
                <div className="left_boxteacher">
                  <svg
                    width="52"
                    height="46"
                    viewBox="0 0 52 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M43.4395 11.7065H37.7505C37.1636 11.7065 36.7072 11.2715 36.7072 10.7465V6.24646C36.7072 3.84649 35.1097 1.82153 32.6319 1.07153C30.1542 0.321533 27.546 1.07153 25.9974 3.0065L19.3139 11.3165C19.1183 11.5565 18.8086 11.7065 18.4826 11.7065H6.5176C3.24106 11.7065 0.583984 14.1515 0.583984 17.1665V35.1965C0.583984 38.2115 3.24106 40.6564 6.5176 40.6564H14.6681L37.4082 45.0664C37.9461 45.1715 38.4677 45.2165 39.0057 45.2165C40.7173 45.2165 42.38 44.6914 43.7492 43.7015L48.1995 40.4765C50.0578 39.1265 51.1174 37.1015 51.1174 34.9265V18.7715C51.1174 14.8715 47.6779 11.7065 43.4395 11.7065ZM12.4675 36.1565H6.5176C5.94701 36.1565 5.47431 35.7365 5.47431 35.1965V17.1665C5.47431 16.6265 5.94701 16.2065 6.5176 16.2065H12.4674V36.1565H12.4675Z"
                      fill="white"
                    />
                  </svg>

                  <div className="text_leftboxteacher">
                    <h1>{classes.numberOfQuestions}</h1>
                    <p>Review</p>
                  </div>
                </div>
                <div className="circle_progressteacher"></div>
              </div>
            </div>
            <div className="bottom_teacher_allclass">
              <div className="text_bottom_teacher">
                <h1>Discover, collect, and use the helpful cource</h1>
                <p>Enter in this creative world. Discover now the latest informative cources.</p>
              </div>
              <div className="butoane_teacher_allclass">
                <button>Discover More</button>
                <button>Hide Promotion</button>
              </div>
            </div>
          </div>
        )}
        <div className="bottom_main_allclases">
          <div className="top_top_main_allclases">
            <div className="class_type_select">
              <button className="selectedButton">
                All Classes <div className="nrclase">{classes.result?.length}</div>
              </button>
              <button>History</button>
            </div>
            <div className="list_view_select">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.944444 7.55556H6.61111C7.13056 7.55556 7.55556 7.13056 7.55556 6.61111V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V6.61111C0 7.13056 0.425 7.55556 0.944444 7.55556ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V10.3889C7.55556 9.86944 7.13056 9.44444 6.61111 9.44444H0.944444C0.425 9.44444 0 9.86944 0 10.3889V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V10.3889C17 9.86944 16.575 9.44444 16.0556 9.44444H10.3889C9.86944 9.44444 9.44444 9.86944 9.44444 10.3889V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V6.61111C9.44444 7.13056 9.86944 7.55556 10.3889 7.55556H16.0556C16.575 7.55556 17 7.13056 17 6.61111V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z"
                  fill="#7749F8"
                />
              </svg>
              <svg
                width="9"
                height="7"
                viewBox="0 0 9 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.76623 6.00621C5.177 6.934 3.823 6.934 3.23378 6.00621L1.2002 2.80416C0.565974 1.80552 1.2834 0.500001 2.46642 0.500001L6.53357 0.5C7.7166 0.5 8.43403 1.80551 7.7998 2.80416L5.76623 6.00621Z"
                  fill="#7749F8"
                />
              </svg>
            </div>
          </div>
          <div className="top_bottom_main_allclases">
            {classes.result.map((clasa, index) => (
              <div className="clasa_box" key={index}>
                <div className="top_clasa_box">
                  <button className="new_button">New</button>
                  <div className="image_class">
                    <img src={clasa.img} alt="" />
                  </div>
                  <button className="favorite_class">
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9439 1.4004C11.9596 -0.00848699 9.50931 0.648993 8.18643 2.26139C6.86355 0.648993 4.41323 -0.0163141 2.42891 1.4004C1.37662 2.15181 0.715187 3.4198 0.670089 4.75825C0.56486 7.79518 3.15048 10.2294 7.09656 13.963L7.17172 14.0334C7.74297 14.5735 8.62238 14.5735 9.19362 14.0256L9.2763 13.9473C13.2224 10.2216 15.8005 7.78735 15.7028 4.75042C15.6577 3.4198 14.9962 2.15181 13.9439 1.4004Z"
                        fill="#FF5B5B"
                      />
                    </svg>
                  </button>
                </div>
                <div className="middle_clasa_box">
                  <div className="texte_middle_clasa_box">
                    <h1>{clasa.shortName}</h1>
                    <h2>{clasa.Name}</h2>
                  </div>
                  <h3>{clasa.level + " " + clasa.board}</h3>
                  <div className="course_lesson">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0085 1.33203H11.8606V5.21694C11.8606 5.48988 11.5846 5.62484 11.3708 5.28214L10.6126 4.06906L9.85447 5.28214C9.63914 5.62332 9.36468 5.48988 9.36468 5.21694V1.33203H5.51616C4.58584 1.33453 3.83301 2.08941 3.83301 3.01974V12.9822C3.83551 13.9108 4.58762 14.6629 5.51616 14.6654H13.01C13.2149 14.665 13.4113 14.5832 13.5559 14.4379C13.7012 14.2934 13.783 14.097 13.7833 13.892V2.10689C13.7825 1.67928 13.4361 1.33287 13.0085 1.33203ZM13.1525 12.6638L5.84067 12.6729C5.6813 12.6839 5.55449 12.8107 5.5435 12.97C5.53133 13.1463 5.66439 13.2991 5.84067 13.3113H13.1525L13.151 13.8996C13.1511 13.9347 13.1375 13.9684 13.1131 13.9936C13.0879 14.018 13.0542 14.0316 13.0191 14.0315H5.51616C4.9404 14.0315 4.47367 13.5648 4.47367 12.989C4.47367 12.4133 4.9404 11.9465 5.51616 11.9465H13.0191C13.0925 11.9474 13.1517 12.0066 13.1525 12.08V12.6638Z"
                        fill="#838383"
                      />
                    </svg>

                    <p>9/12 Lessons Left</p>
                  </div>
                  <div className="progress_bar">
                    <div className="bara_progress"></div>
                  </div>
                </div>
                <div className="bottom_clasa_box">
                  <div className="top_butoane_clasa_box">
                    <button
                      onClick={() =>
                        showTopic(
                          clasa.topics,
                          clasa.Name,
                          clasa.originalClassId
                        )
                      }
                    >
                      Learn
                    </button>
                    <button
                      onClick={() =>
                        handleTryExamQuestions(
                          clasa.topics,
                          clasa.originalClassId
                        )
                      }
                    >
                      Attempt QA
                    </button>
                    <button>Try Exam</button>
                  </div>
                  {isTeacher && (
                      <button className="huge_button" onClick={()=> showAnalytics(clasa.topics, clasa.Name, clasa.originalClassId)}>Analytics</button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <CalendarEvents />
    </div>
  );
};

export default AllClasses;
