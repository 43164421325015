import React from "react";
import Cookies from 'js-cookie';
import CalendarEvents from "./CalendarEvents";
import urlConfig from '../../configs/urlConfig';
import { useParams } from "react-router-dom";
import GraphParts from "./GraphParts";
import "./styles.css";
const Analytics = () => {
  const { origClassId } = useParams()
  const identifier = Cookies.get("access_token")
  const [viewFilter, setViewFilter] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [studentsData, setStudentsData] = React.useState<{ gradePercentage: number, predictGrade: string, name: string, postDate: string }[]>([]);

  const [filterPredictGrade, setFilterPredictGrade] = React.useState<string>("");
  const [filterPostDate, setFilterPostDate] = React.useState<string>("");

  React.useEffect(() => {
    const data = { identifier: identifier, courseId: origClassId }
    // try {
    console.log(data)
    const response = fetch(urlConfig.apiUrl + "/getAllUserAnalytics", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: "cors",
      body: JSON.stringify(data),
    });

    response.then((res) => {
      res.json().then((data) => {
        console.log(data)
        setStudentsData(data)
      })
    })
  }, [])

  let teacher = "teacher";

  return (
    <div className="main-cont_clases">
      {teacher === "teacher" ? (
        <div className="teacher_analytics_cont">
          <div className="top_teacher">
            <div className="top_teacher_box">
              <div className="texte_cont">
                <h1>20</h1>
                <p>Active Projects</p>
              </div>
              <img src={"https://i.imgur.com/GsiuRlk.png"} alt="" />
            </div>
            <div className="top_teacher_box">
              <div className="texte_cont">
                <h1>58</h1>
                <p>Ongoing Tasks</p>
              </div>
              <img src={"https://i.imgur.com/DZTt2SB.png"} alt="" />
            </div>
            <div className="top_teacher_box">
              <div className="texte_cont">
                <h1>4h 28min</h1>
                <p>Total Hours</p>
              </div>
              <img src={"https://i.imgur.com/Sd68luE.png"} alt="" />
            </div>
          </div>
          <div className="top_advertise_main_allclases">
            <div className="cont_top_advertise_main_allclases">
              <h1>Discover, collect, and use helpful courses</h1>
              <p>
                Enter this creative world. Discover now the latest informative
                courses.
              </p>
              <div className="button_conts">
                <button>Discover More</button>
                <button className="non_selected">Hide Promotion</button>
              </div>
            </div>
          </div>
          <div className="bottom_teacher">
            <div className="top_bottom_teacher">
              <div className="studentListSearch">
                <h1>Student List</h1>
                <div className="filter_part">
                  <div className="filter_cont" onClick={() => setViewFilter(!viewFilter)} style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <path
                        d="M2.31621 0C0.557209 0 -0.381791 2.024 0.776209 3.317L6.19821 9.376V14.25C6.19821 14.722 6.42521 15.167 6.81121 15.45L9.88021 17.7C10.8902 18.442 12.3342 17.736 12.3342 16.5V9.376L17.7562 3.317C18.9132 2.024 17.9742 0 16.2162 0H2.31621Z"
                        fill="#7749F8"
                      />
                    </svg>
                    <span>Filter</span>
                  </div>
                  {viewFilter && (
                    <div className="filter_modal">
                      <div className="filter_modal_content">
                        <div className="filter_modal_header">
                          <h2>Filter Options</h2>
                          <button className="close_button" onClick={() => setViewFilter(false)}>×</button>
                        </div>
                        <div className="filter_modal_body">
                          <div className="filter_field">
                            <label htmlFor="predictGrade">Predicted Grade:</label>
                            <input
                              id="predictGrade"
                              type="text"
                              value={filterPredictGrade}
                              onChange={(e) => setFilterPredictGrade(e.target.value)}
                              placeholder="Enter Grade"
                            />
                          </div>
                          <div className="filter_field">
                            <label htmlFor="postDate">Post Date:</label>
                            <input
                              id="postDate"
                              type="date"
                              value={filterPostDate}
                              onChange={(e) => setFilterPostDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="filter_modal_footer">
                          <button className="apply_button" onClick={() => setViewFilter(false)}>Apply Filters</button>
                          <button className="reset_button" onClick={() => { setFilterPredictGrade(''); setFilterPostDate(''); }}>Reset</button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="input_cont">
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8261 15.4395L14.0184 11.6318C14.8051 10.446 15.2656 9.02625 15.2656 7.5C15.2656 3.3645 11.9011 0 7.76562 0C3.63013 0 0.265625 3.3645 0.265625 7.5C0.265625 11.6355 3.63013 15 7.76562 15C9.29187 15 10.7116 14.5395 11.8974 13.7528L15.7051 17.5605C16.2901 18.1462 17.2411 18.1462 17.8261 17.5605C18.4119 16.9748 18.4119 16.0252 17.8261 15.4395ZM2.51562 7.5C2.51562 4.605 4.87063 2.25 7.76562 2.25C10.6606 2.25 13.0156 4.605 13.0156 7.5C13.0156 10.395 10.6606 12.75 7.76562 12.75C4.87063 12.75 2.51562 10.395 2.51562 7.5Z"
                        fill="#7749F8"
                      />
                    </svg>
                    <input type="text" placeholder="Search.." onChange={(e) => setSearch(e.target.value)} value={search} />
                  </div>
                </div>
              </div>
              <div className="categories_student">
                <div className="student_box">
                  <div className="patrateldala"></div>
                  <span>Student</span>
                </div>
                <span style={{ marginLeft: "145px" }}>Predicted Grade</span>
                <span style={{ marginLeft: "102px" }}>Courses Progress</span>
                <span style={{ marginLeft: "111px" }}>Post Date</span>
                <span style={{ marginLeft: "138px" }}>File</span>
              </div>
            </div>
            <div className="bottom_bottom_teacher">

              {studentsData
                .filter(student => student.name.toLowerCase().includes(search.toLowerCase()))
                .filter(student => filterPredictGrade ? student.predictGrade.toLowerCase().includes(filterPredictGrade.toLowerCase()) : true)
                .filter(student => filterPostDate ? student.postDate === filterPostDate : true)
                .map((student) => (
                  <div className="student_boxcont" key={student.name}>
                    <div className="student_data">
                      <div className="check_box">
                        <svg
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.26562 5L4.76562 8.5L11.76562 1"
                            stroke="#7749F8"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="pic_cont">
                        <img src={"https://i.imgur.com/DZTt2SB.png"} alt="" />
                      </div>
                      <span>{student.name}</span>
                    </div>
                    <div className="grade">{student.predictGrade}</div>
                    <div className="course_progress">
                      <span>{(student.gradePercentage).toFixed(0)}%</span>
                      <div className="prog_course">
                        <div className="progres_bar_curs" style={{ width: `${student.gradePercentage}%` }}></div>
                      </div>
                    </div>
                    <div className="post_date">{student.postDate}</div>
                    <button>Ahead of Schedule</button>
                  </div>
                ))}

            </div>
          </div>
        </div>
      ) : (
        <div className="student_analytics_cont">
          <GraphParts />
        </div>
      )}

      <CalendarEvents />
    </div>
  );
};

export default Analytics;