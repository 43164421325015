// import pptxgen from "pptxgenjs";
const makePowerpointPresentation = function (slides: any) {
    // slides = slides.slides
    // // let pres = new pptxgen();
    // for (const slide in slides) {
    //     // const slideP = pres.addSlide();
    //     let textboxText = slides[slide].slideTitle;
    //     let textboxOpts = { x: 1, y: 1, color: "363636" };
    //     slideP.addText(textboxText, textboxOpts);

    //     textboxText = slides[slide].slideContent;
    //     textboxOpts = { x: 2, y: 2, color: "363636" };
    //     slideP.addText(textboxText, textboxOpts);
    // }
    // pres.writeFile();
}
export default makePowerpointPresentation