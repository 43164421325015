import React, { useEffect, useRef, useState } from "react";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const GraphParts = () => {
    const data = {
        labels: ["Dec 01", "Dec 02", "Dec 03", "Dec 04", "Dec 05", "Dec 06", "Dec 07", "Dec 08"],
        datasets: [
          {
            label: 'Dataset 1',
            data: [10, 50, 80, 60, 30, 70, 80, 50],
            backgroundColor: 'transparent',
            borderColor: "#7E5FE7",
            borderWidth: 8,
            pointBackgroundColor: '#7E5FE7',
            pointBorderColor: '#fff',
            pointBorderWidth: 4,
            pointRadius: 15,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 5,
            tension: 0.4, 
          },
          {
            label: 'Dataset 2',
            data: [30, 10, 50, 40, 60, 50, 40, 30],
            backgroundColor: 'transparent',
            borderColor: "#FF7213",
            borderWidth: 8, 
            pointBackgroundColor: '#FF7213',
            pointBorderColor: '#fff',
            pointBorderWidth: 4, 
            pointRadius: 15,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 5,
            tension: 0.4, 
          }
        ]
      };
    
      const options = {
        plugins: {
          legend: false, 
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItem) => `${tooltipItem.raw} %`
            },
            titleFont: {
              family: 'Poppins',
              weight: '400',
              size: 14,
            },
            bodyFont: {
              family: 'Poppins',
              weight: '400',
              size: 14,
            },
            bodyColor: 'var(--Grey-03, #818181)',
          }
        },
        scales: {
          x: {
            grid: {
              display: false 
            },
            ticks: {
              color: 'var(--Grey-03, #818181)',
              font: {
                family: 'Poppins',
                weight: '400',
                size: 14,
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              callback: (value) => value + "%",
              color: 'var(--Grey-03, #818181)',
              font: {
                family: 'Poppins',
                weight: '400',
                size: 14,
              }
            },
            grid: {
              drawBorder: false, 
              borderDash: [10],
              color: 'rgba(175, 175, 175, 0.23)'
            }
          }
        },
        elements: {
          point: {
            radius: 15,
            hoverRadius: 20,
            hoverBorderWidth: 5,
            borderWidth: 4
          }
        }
      };

  const progressPathRefs = {
    progress1: useRef(null),
    progress2: useRef(null),
    progress3: useRef(null),
    progress4: useRef(null),
  };

  const [progressValues, setProgressValues] = useState({
    progress1: 0.82,
    progress2: 0.6,
    progress3: 0.4,
    progress4: 0.2,
  });

  const setProgress = (progressRef, progressValue) => {
    if (progressRef.current) {
      const pathLength = progressRef.current.getTotalLength();
      progressRef.current.style.strokeDasharray = pathLength;
      progressRef.current.style.strokeDashoffset = pathLength;
      setTimeout(() => {
        progressRef.current.style.transition = "stroke-dashoffset 2s ease-in-out";
        progressRef.current.style.strokeDashoffset = pathLength * (1 - progressValue);
      }, 100);
    }
  };

  useEffect(() => {
    setProgress(progressPathRefs.progress1, progressValues.progress1);
    setProgress(progressPathRefs.progress2, progressValues.progress2);
    setProgress(progressPathRefs.progress3, progressValues.progress3);
    setProgress(progressPathRefs.progress4, progressValues.progress4);
  }, [progressValues]);

  return (
    <div className="cont_GraphParts">
      <div className="cont_statitics">
        <div className="top_part">
          <h1>Current Statistic</h1>
          <div className="linie"></div>
        </div>
        <div className="stats_part">
          <svg className="graph_parts1"
            width="272"
            height="143"
            viewBox="0 0 272 143"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M265.5 136.501C265.5 64.9801 207.521 7.00097 136 7.00098C64.4791 7.00098 6.5 64.9801 6.5 136.501"
              stroke="#EEEEEE"
              strokeWidth="2"
            />

            <path
              ref={progressPathRefs.progress1}
              d="M265.5 136.501C265.5 64.9801 207.521 7.00097 136 7.00098C64.4791 7.00098 6.5 64.9801 6.5 136.501"
              stroke="#FFAF65"
              strokeWidth="13"
              strokeLinecap="round"
              fill="none"
            />
          </svg>
          <svg className="graph_parts2"
            width="222"
            height="118"
            viewBox="0 0 222 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M215.125 111.5C215.125 53.9933 168.507 7.375 111 7.375C53.4933 7.375 6.875 53.9934 6.875 111.5"
              stroke="#EEEEEE"
              strokeWidth="2"
            />

            <path
              ref={progressPathRefs.progress2}
              d="M215.125 111.5C215.125 53.9933 168.507 7.375 111 7.375C53.4933 7.375 6.875 53.9934 6.875 111.5"
              stroke="#7E5FE7"
              strokeWidth="13"
              strokeLinecap="round"
              fill="none"
            />
          </svg>
          <svg className="graph_parts3"
            width="174"
            height="94"
            viewBox="0 0 174 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M167.042 87.5026C167.042 43.2968 131.206 7.46094 87.0007 7.46094C42.7949 7.46094 6.95898 43.2968 6.95898 87.5026"
              stroke="#EEEEEE"
              strokeWidth="2"
            />

            <path
              ref={progressPathRefs.progress3}
              d="M167.042 87.5026C167.042 43.2968 131.206 7.46094 87.0007 7.46094C42.7949 7.46094 6.95898 43.2968 6.95898 87.5026"
              stroke="#60C695"
              strokeWidth="13"
              strokeLinecap="round"
              fill="none"
            />
          </svg>
          <svg className="graph_parts4"
            width="120"
            height="67"
            viewBox="0 0 120 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M113.5 60.5C113.5 30.9528 89.5472 7 60 7C30.4528 7 6.5 30.9528 6.5 60.5"
              stroke="#EEEEEE"
              strokeWidth="2"
            />

            <path
              ref={progressPathRefs.progress4}
              d="M113.5 60.5C113.5 30.9528 89.5472 7 60 7C30.4528 7 6.5 30.9528 6.5 60.5"
              stroke="#EE715A"
              strokeWidth="13"
              strokeLinecap="round"
              fill="none"
            />
          </svg>
        </div>
        <div className="rate_comp_cont">
          <div className="rate_comp">
            <div className="left_part">
              <div className="dot" style={{ background: "#FFAF65" }}></div>
              <h2>Completion Rate</h2>
            </div>
            <p id="progress1_text">({Math.round(progressValues.progress1 * 100)}%)</p>
          </div>
          <div className="rate_comp">
            <div className="left_part">
              <div className="dot" style={{ background: "#7E5FE7" }}></div>
              <h2>Completion Rate</h2>
            </div>
            <p id="progress2_text">({Math.round(progressValues.progress2 * 100)}%)</p>
          </div>
          <div className="rate_comp">
            <div className="left_part">
              <div className="dot" style={{ background: "#60C695" }}></div>
              <h2>Completion Rate</h2>
            </div>
            <p id="progress3_text">({Math.round(progressValues.progress3 * 100)}%)</p>
          </div>
          <div className="rate_comp">
            <div className="left_part">
              <div className="dot" style={{ background: "#EE715A" }}></div>
              <h2>Completion Rate</h2>
            </div>
            <p id="progress4_text">({Math.round(progressValues.progress4 * 100)}%)</p>
          </div>
        </div>
      </div>
      <div className="cont_graph">
        <div className="chart_style">
               <Line data={data} options={options}></Line>
        </div>

      </div>

    </div>
  );
};

export default GraphParts;
