import Cookies from "js-cookie";
import useGenericMutation from "../components/shared/useGenericMutation";
import urlConfig from "../configs/urlConfig";
import { QuestionRequest } from "../interfaces/Question";


export const useModuleQuestions = () => {
    return useGenericMutation({
        mutationKey: ['moduleQuestions'],
        mutationFn: getModuleQuestions,
        onSuccess: (data) => {
            return data;
        },
    });
}
export const useVideoLinkQA = () => {
    return useGenericMutation({
        mutationKey: ['revisionContent'],
        mutationFn: getVideoLinkQA,
        onSuccess: (data) => {
            return data;
        },

        onError: (error) => {
            console.log('Error fetching video link:', error);
        }
    });
}


const getVideoLinkQA = async (video_id: string) => {

    const identifier = Cookies.get("access_token")

    const data = { identifier: identifier, _id: video_id }
    // try {
    const response = await fetch(urlConfig.apiUrl + "/getVideoLinkForQuestion", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        mode: "cors",
        body: JSON.stringify(data),
    });

    if (response.ok) {
        let res = await response.json()
        return res;
    } else {
        console.log('Error:', response.status);
    }
    // } catch (error) {
    //     console.log('Error:', error);
    // }
};

export interface MarkAnswerRequest {
    _id: string;
    answer: string;
    originalClassId: string
}
export const markAnswer = async ({ _id, answer, originalClassId }: MarkAnswerRequest) => {

    const identifier = Cookies.get("access_token")
    const response = await fetch(`${urlConfig.apiUrl}/markAnswer`, {
        method: 'POST',
        headers: {
            // Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            identifier: identifier,
            _id: _id,
            answer: answer,
            originalClassId: originalClassId
        }),
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    // // Parse the JSON response body
    // const responseData = await response.json();
    // return responseData; // This contains the JSON object returned by the server

    // Parse the custom formatted response
    // const parsedData = await parseResponse(response);
    // return parsedData;


    return response;

}

const getModuleQuestions = async ({ identifier, topic,
    subtopic, contentName, originalClassId,isExam }: QuestionRequest) => {
    try {
        const response = await fetch(`${urlConfig.apiUrl}/getQuestions`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                identifier: identifier,
                topic: topic,
                subtopic: subtopic,
                ContentName: contentName,
                originalClassId: originalClassId,
                isExam:isExam,
            }),
        });

        const data = await response.json()

        console.log('data ==>', data)

        if (data) {
            return data
        } else {
            console.log('no questions');
        }

    } catch (error) {
        console.log('Error:', error);
    }
}

export const getEnrollableModules = async (identifier: string) => {
    try {
        const response = await fetch(`${urlConfig.apiUrl}/getEnroalbleModules`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ identifier }),
        });

        const data = await response.json()
        console.log(data)
        if (data.ok) {
            return data.result
        } else {
            console.log('no enrolled modules');
            // Handle error cases
        }
    } catch (error) {
        console.log('Error:', error);
        // Handle network errors
    }
}
