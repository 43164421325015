import React, { useState } from "react";
import "./styles.css";
import QuizRightBar from "./QuizRightBar";

const QuestionBox = ({ id, question, expandedBoxes, toggleExpand }) => {
  const isExpanded = expandedBoxes.includes(id);

  return (
    <div
      className="question_box"
      style={{ height: isExpanded ? "20.781vw" : "6.25vw" }}
    >
      <div className="top_part">
        <svg
          width="22"
          height="27"
          viewBox="0 0 22 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 3.37613C2.61205e-05 3.19132 0.0365213 3.00838 0.10735 2.838C0.17818 2.66762 0.28192 2.51323 0.4125 2.38386C1.91104 0.89913 3.91304 0.0473562 6.01103 0.00191591C8.10901 -0.0435244 10.1453 0.720785 11.7054 2.13927L12.188 2.5951C13.191 3.48328 14.479 3.97292 15.8125 3.97292C17.146 3.97292 18.434 3.48328 19.437 2.5951L19.7794 2.27964C20.6181 1.6084 21.879 2.14483 21.9931 3.21909L22 3.37613V15.8837C22 16.0685 21.9635 16.2515 21.8926 16.4218C21.8218 16.5922 21.7181 16.7466 21.5875 16.876C20.089 18.3607 18.087 19.2125 15.989 19.2579C13.891 19.3034 11.8547 18.539 10.2946 17.1206L9.812 16.6647C8.84061 15.8045 7.60068 15.3172 6.30949 15.2882C5.0183 15.2593 3.7582 15.6905 2.75 16.5063V25.6118C2.74961 25.966 2.61542 26.3067 2.37483 26.5643C2.13425 26.8219 1.80544 26.9769 1.45558 26.9976C1.10572 27.0184 0.761229 26.9033 0.492481 26.676C0.223732 26.4486 0.0510175 26.1261 0.00962496 25.7744L0 25.6118V3.37613Z"
            fill="#7749F8"
          />
        </svg>
        <h1>
          <span>Q{id}: </span>
          {question}
        </h1>
        <div className="expand_or_mini" onClick={() => toggleExpand(id)}>
          <span></span>
          <span
            style={{
              transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          ></span>
        </div>
      </div>
      <div
        className="bottom_question"
        style={{ display: isExpanded ? "flex" : "none" }}
      >
        <button>A. Program Counter</button>
        <button className="selected_quest_button">B. Program Counter</button>
        <button>C. Program Counter</button>
        <button>D. Program Counter</button>
      </div>
    </div>
  );
};

const FlagQuestions = () => {
  const [expandedBoxes, setExpandedBoxes] = useState([]);
  const questions = [
    {
      id: 1,
      text: "How do the ALU, Control Unit, and Registers work together to process data?",
    },
    {
      id: 2,
      text: "Explain the function of a Program Counter in a CPU.",
    },
    {
      id: 3,
      text: "What role does cache memory play in a computer system?",
    },
  ];

  const toggleExpand = (id) => {
    setExpandedBoxes((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  };
  return (
    <div className="flag_cont">
      <div className="flag_title">
        <svg
          width="10"
          height="19"
          viewBox="0 0 10 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 17.4472L8.51275 19L0.412044 10.5372C0.281465 10.4016 0.177836 10.2403 0.107121 10.0627C0.0364052 9.88506 0 9.69458 0 9.5022C0 9.30982 0.0364052 9.11933 0.107121 8.94171C0.177836 8.76409 0.281465 8.60284 0.412044 8.46723L8.51275 0L9.9986 1.55281L2.39552 9.5L10 17.4472Z"
            fill="#7749F8"
          />
        </svg>

        <h1>Flag Questions</h1>
      </div>

      <div className="flag_big_cont">
        <div className="flag_quest_cont">
        {questions.map((question) => (
        <QuestionBox
          key={question.id}
          id={question.id}
          question={question.text}
          expandedBoxes={expandedBoxes}
          toggleExpand={toggleExpand}
        />
      ))}
        </div>
        <QuizRightBar />
      </div>
    </div>
  );
};

export default FlagQuestions;
