import { Box, CircularProgress, Button } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";
import TopicSubtopicContentSelector from "../../components/shared/TopicSubtopicContentSelector";
import { Topic } from "../../interfaces/module";
import { RevisionData } from "../../interfaces/Revision";
import { useClassRevisionContent } from "../../services/revision";
import { useCallback, useEffect } from "react";
import makePowerpointPresentation from "../../services/presentation";
import urlConfig from "../../configs/urlConfig";
import Cookies from "js-cookie";
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface Event {
    _id: string;
    ContentName: string;
    identifier: string;
    originalClassId: string;
    subtopic: string;
    topic: string;
}


const Container = styled.div`
    background: #F8F9FA;
    min-height: '100%';
    padding: 2rem;
`;



const TopicSection = styled.div`
        flex:6;
        margin:20px;
        font-size: 24px;
        font-weight: 600;
        overflow-wrap: break-word;
    `

const Section = styled.section`
background: white;
border-radius: 12px;
overflow: hidden;
border: 1px solid rgb(222, 226, 230);
justify-content: flex-start;
align-items: center;
margin-bottom:16px;
display: flex;
align-items:stretch;

`
const ColorBox = styled.div`
flex:1;
background: rgb(119, 73, 248); 
border-top-left-radius: 4px; 
border-top-right-radius: 4px;
`

const TopicButtonSection = styled.div`

padding: 16px 24px;
text-align:center;
margin:1rem;
background: rgb(119, 73, 248);
border-radius: 6px;
color: white;
font-size: 16px;
font-weight: 600;
overflow-wrap: break-word;`


const UpcomingExams: React.FC = () => {
    // /Class/Questions/Characteristics%20of%20Contemporary%20Processors%20and%20Devices/Structure%20and%20Function%20of%20the%20Processor/ALU,%20Control%20Unit,%20and%20Registers/6494aa0f78b2e4fbe2daaf48

    const { origClassId } = useParams()
    const navigate = useNavigate();
    const [events, setEvents] = React.useState<Event[]>([]);

    const GetExams = async (): Promise<Event[]> => {
        const response = await fetch(`${urlConfig.apiUrl}/getExams`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ identifier: Cookies.get("access_token"), origClassId: origClassId }),
        });


        if (response.ok) {
            const data: Event[] = await response.json();
            return data;
        } else {
            throw response;
        }
    };

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const fetchedEvents = await GetExams();
                console.log(fetchedEvents)
                setEvents(fetchedEvents);
            } catch (error) {
                console.error("Failed to fetch events", error);
            }
        };
        fetchEvents();
    }, []);
    return <Container>
        {events.map((event, index) => (
                    <Section onClick={() => navigate("/Class/Questions/"+event.topic+"/"+event.subtopic+"/"+event.ContentName+"/"+event.originalClassId+"/"+event._id)}>
                    <ColorBox></ColorBox>
                    <TopicSection >
                        <div>
                            <span>{event.ContentName} ({event.subtopic})</span>
                        </div>
                        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
        
                            <span style={{ fontSize: '18px', color: '#7749F8', fontWeight: 700 }}>{event.topic}</span>
                        </div>
        
                    </TopicSection>
        
                    <div style={{ alignContent: 'center' }}><TopicButtonSection>GO TO EXAM</TopicButtonSection></div>
                </Section>
        ))}

    </Container>
}

export default UpcomingExams;
