import Cookies from "js-cookie";
import urlConfig from "../configs/urlConfig";
import { ContentData } from "../interfaces/module";

import useGenericMutation from "../components/shared/useGenericMutation";
import useGenericQuery from "../components/shared/useGenericQuery";
import { Module } from '../interfaces/module';


///HOOKS
export const useGetDisciplines = () => {
    return useGenericQuery<{result:Module[],ok:boolean,numberOfQuestions:number}>({
        queryKey: ['myModules'],
        queryFn: () => getMyModules(),
        refetchOnWindowFocus:true, //To fetch the service when the user comes back
    });
}


export const useGetEnrollableModules = () => {
    return useGenericQuery<Module[]>({
        queryKey: ['enrollableModules'],
        queryFn: () => getEnrollableModules(),
        refetchOnWindowFocus: true,
        // staleTime: Infinity
    });
}

export const useEnrollInClass = () => {
    return useGenericMutation({
        mutationKey: ['enrollInClass'],
        mutationFn: enrollInClass,
        onSuccess: (data) => {
            return data;
        },
    })
}

// API CALLS

const getMyModules = async () => {
    const identifier = Cookies.get("access_token")
    const response = await fetch(`${urlConfig.apiUrl}/getDisciplines`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identifier: identifier }),
    });



    if (response.ok) {
        const data = await response.json()
        if (data.ok) {
            return data
        } else {
            
            console.log('no enrolled modules');
            return [];
        }
    } else {
        throw response;
    }

}

const getEnrollableModules = async () => {
    const identifier = Cookies.get("access_token")
    const response = await fetch(`${urlConfig.apiUrl}/getEnroalbleModules`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identifier }),
    });

    if (!response.ok) throw response;
    const data = await response.json()
    console.log(data)
    if (data.ok) {
        return data.result
    } else {
        console.log('no enrolled modules');
        // Handle error cases
    }

}


const getModule = async (contentData: { identifier: string; id: string }) => {
    try {
        const response = await fetch(`${urlConfig.apiUrl}/getModule`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contentData),
        });

        const data = await response.json()
        console.log(data)
        if (data.ok) {
            return data.result
        } else {
            console.log(response);
            // Handle error cases
        }
    } catch (error) {
        console.log('Error:', error);
        // Handle network errors
    }
}

const enrollInClass = async (contentData: ContentData) => {
    try {
        const response = await fetch(`${urlConfig.apiUrl}/enrollInClass`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contentData),
        });

        const data = await response.json()
        console.log(data)
        if (data.ok) {
            return data.result
        } else {
            console.log('no enrolled modules');
            // Handle error cases
        }
    } catch (error) {
        console.log('Error:', error);
        // Handle network errors
    }
}


