import React, { useEffect, useRef, useState } from "react";

import './styles.css'


const HourTracker = () => {

  const progressPathRefs = {
    progress1: useRef(null),
  };

  const [progressValues, setProgressValues] = useState({
    progress1: 0.82,
  });

  const setProgress = (progressRef, progressValue) => {
    if (progressRef.current) {
      const pathLength = progressRef.current.getTotalLength();
      progressRef.current.style.strokeDasharray = pathLength;
      progressRef.current.style.strokeDashoffset = pathLength;
      setTimeout(() => {
        progressRef.current.style.transition = "stroke-dashoffset 2s ease-in-out";
        progressRef.current.style.strokeDashoffset = pathLength * (1 - progressValue);
      }, 100);
    }
  };

  useEffect(() => {
    setProgress(progressPathRefs.progress1, progressValues.progress1);
  }, [progressValues]);
  return (
    <div className='hourTracker'>
             <div className="cont_hourtracker">
                  <div className="eclipse_cont">
                     <img src={"https://i.imgur.com/onGtHUo.png"} alt="" />
                     <h1>6h 20m</h1>
                  </div>
                  <div className="cont_maxmin">
                    <p>0</p>
                    <p>8h</p>
                  </div>

                  <div className="cont_svg_prog">
                
                  <svg width="300" height="237" viewBox="0 0 300 237" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M272.641 236.366C288.19 214.286 297.49 188.418 299.559 161.492C301.628 134.566 296.39 107.581 284.396 83.3846C272.403 59.1884 254.1 38.6787 231.419 24.0203C208.738 9.36184 182.521 1.0984 155.534 0.102111C128.547 -0.894174 101.791 5.41365 78.0913 18.3598C54.3913 31.306 34.6259 50.4103 20.8813 73.6564C7.13664 96.9024 -0.0773734 123.428 0.00062566 150.433C0.0786247 177.439 7.44574 203.922 21.3244 227.088L34.1932 219.379C21.7025 198.529 15.0722 174.694 15.002 150.39C14.9318 126.085 21.4243 102.213 33.7944 81.2914C46.1644 60.3702 63.953 43.1765 85.2828 31.5251C106.613 19.8737 130.692 14.1967 154.98 15.0933C179.268 15.99 202.864 23.427 223.277 36.6194C243.689 49.8119 260.162 68.2704 270.955 90.0468C281.749 111.823 286.464 136.11 284.602 160.343C282.74 184.576 274.37 207.857 260.376 227.729L272.641 236.366Z" fill="#C1ABFF"/>
<mask id="path-2-inside-1_303_3728" fill="white">
<path d="M286.078 86.8912C277.45 68.2872 265.099 51.65 249.788 38.0074C234.477 24.3648 216.531 14.0067 197.059 7.57301C177.587 1.13931 157.003 -1.23331 136.578 0.601742C116.153 2.43679 96.3207 8.44053 78.3073 18.242C60.294 28.0435 44.4818 41.4346 31.8476 57.5878C19.2134 73.7411 10.0257 92.3135 4.852 112.157C-0.321661 132.001 -1.37135 152.696 1.7678 172.961C4.90695 193.227 12.1682 212.634 23.1031 229.982L36.1236 221.776C26.3108 206.207 19.7946 188.791 16.9775 170.605C14.1605 152.419 15.1025 133.848 19.7453 116.04C24.3881 98.2325 32.6331 81.5658 43.9709 67.07C55.3087 52.5742 69.4985 40.5572 85.6636 31.7614C101.829 22.9656 119.626 17.5779 137.955 15.9311C156.284 14.2844 174.756 16.4135 192.23 22.1871C209.704 27.9607 225.809 37.2559 239.549 49.4987C253.289 61.7414 264.373 76.6716 272.116 93.3667L286.078 86.8912Z"/>
</mask>
<path d="M286.078 86.8912C277.45 68.2872 265.099 51.65 249.788 38.0074C234.477 24.3648 216.531 14.0067 197.059 7.57301C177.587 1.13931 157.003 -1.23331 136.578 0.601742C116.153 2.43679 96.3207 8.44053 78.3073 18.242C60.294 28.0435 44.4818 41.4346 31.8476 57.5878C19.2134 73.7411 10.0257 92.3135 4.852 112.157C-0.321661 132.001 -1.37135 152.696 1.7678 172.961C4.90695 193.227 12.1682 212.634 23.1031 229.982L36.1236 221.776C26.3108 206.207 19.7946 188.791 16.9775 170.605C14.1605 152.419 15.1025 133.848 19.7453 116.04C24.3881 98.2325 32.6331 81.5658 43.9709 67.07C55.3087 52.5742 69.4985 40.5572 85.6636 31.7614C101.829 22.9656 119.626 17.5779 137.955 15.9311C156.284 14.2844 174.756 16.4135 192.23 22.1871C209.704 27.9607 225.809 37.2559 239.549 49.4987C253.289 61.7414 264.373 76.6716 272.116 93.3667L286.078 86.8912Z" stroke="#7749F8" stroke-width="26" mask="url(#path-2-inside-1_303_3728)"/>
</svg>

                  </div>
             </div>
    </div>
  )
}

export default HourTracker