import React, { useState, useEffect } from "react";
import urlConfig from "../../configs/urlConfig";
import dayjs from "dayjs";
import "./styles.css";
import Cookies from "js-cookie";

interface Event {
  _id: string;
  description: string;
  goToLink: string;
  timestamp: string;
  title: string;
}

const CalendarEvents = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [days, setDays] = useState<(number | null)[]>([]);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [events, setEvents] = useState<Event[]>([]);

  const getUpcomingEvents = async (): Promise<Event[]> => {
    const response = await fetch(`${urlConfig.apiUrl}/getUpcomingEvents`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ identifier: Cookies.get("access_token") }),
    });

    if (response.ok) {
      const data: Event[] = await response.json();
      return data;
    } else {
      throw response;
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    generateDays();
    if (isCurrentMonth()) {
      setSelectedDay(currentDate.date());
    }
  }, [currentDate]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await getUpcomingEvents();
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Failed to fetch events", error);
      }
    };
    fetchEvents();
  }, []);

  const generateDays = () => {
    const daysInMonth = currentDate.daysInMonth();
    const firstDayOfMonth = currentDate.startOf("month").day();

    const daysArray: (number | null)[] = [];
    const startOfWeekIndex = currentDate.date() - currentDate.day();

    for (let i = startOfWeekIndex; i < startOfWeekIndex + 7; i++) {
      if (i > 0 && i <= daysInMonth) {
        daysArray.push(i);
      } else {
        daysArray.push(null);
      }
    }

    setDays(daysArray);
  };

  const isCurrentMonth = () => {
    const today = dayjs();
    return (
      currentDate.month() === today.month() && currentDate.year() === today.year()
    );
  };

  const handlePrevMonth = () => {
    const previousMonth = currentDate.subtract(1, "month");
    setCurrentDate(previousMonth);
    setSelectedDay(null);
  };

  const handleNextMonth = () => {
    const nextMonth = currentDate.add(1, "month");
    setCurrentDate(nextMonth);
    setSelectedDay(null);
  };

  const handleDayClick = (day: number | null) => {
    if (day) {
      setSelectedDay(day);
    }
  };

  return (
    <div className="calendar_right_bar_cont">
      <div className="top_cont_calendar">
        <div className="top_top_cont_calendar">
          <div className="avatar_cont">
            <div className="avatarBackground"></div>
            <div className="avatarIcon"></div>
          </div>
          <div className="name_email_cont">
            <h2>Adela Parkson</h2>
            <p>adelaparson@gmail.com</p>
          </div>
        </div>

        <div className="top_bottom_cont_calendar">
          <div className="data_box">
            <div className="icon_data_box">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* SVG Path */}
              </svg>
            </div>
            <div className="texte_data_box">
              <p>Total Courses</p>
              <h3>24</h3>
            </div>
          </div>
          <div className="data_box">
            <div className="icon_data_box">
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* SVG Path */}
              </svg>
            </div>
            <div className="texte_data_box">
              <p>Exams</p>
              <h3>24</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_cont_calendar">
        <div className="calendar_data">
          <div className="calendar_data_cont">
            <div className="month_navigate">
              <h1>{`${monthNames[currentDate.month()]}, ${currentDate.year()}`}</h1>
              <div className="navigate">
                <svg
                  onClick={handlePrevMonth}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* SVG Path */}
                </svg>
                <svg
                  onClick={handleNextMonth}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* SVG Path */}
                </svg>
              </div>
            </div>

            <div className="days_letter_cont">
              <h1>M</h1>
              <h1>T</h1>
              <h1>W</h1>
              <h1>T</h1>
              <h1>F</h1>
              <h1>S</h1>
              <h1>S</h1>
            </div>

            <div className="days_number_cont">
              {days.map((day, index) => (
                <h2
                  key={index}
                  className={day === selectedDay ? "day_selected" : ""}
                  onClick={() => handleDayClick(day)}
                >
                  {day || ""}
                </h2>
              ))}
            </div>
          </div>
        </div>
        <div className="upcoming_events_data">
          <h1>Upcoming Events</h1>
          {events.map((event) => (
            <div className="upcoming_event_box" key={event._id}>
              <div className="top_upcoming_event_box">
                <h2>{dayjs(event.timestamp).format("D MMM")}</h2>
                <div className="linie"></div>
                <div className="dots_cont">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="bottom_upcoming_event_box">
                <div className="event_box">
                  <h3>{dayjs(event.timestamp).format("hh:mm A")}</h3>
                  <div className="bara"></div>
                  <div className="texte_event_box">
                    <h4>{event.title}</h4>
                    <h5>{event.description}</h5>
                  </div>
                  <button
                      onClick={() => window.open(event.goToLink, "_blank")}
                      className="go-to-link-button">
                      View
                    </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarEvents;
