import React from "react";
import HourTracker from "./HourTracker";
const QuizRightBar = () => {
  return (
    <div className="contQuizRight">
      <HourTracker />
      <div className="bottom_quizright">
        <div className="add_notes">
          <h1>Add Note here..</h1>
          <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 11C21 10.7016 20.9078 10.4155 20.7437 10.2045C20.5796 9.99353 20.3571 9.875 20.125 9.875H0.875C0.642936 9.875 0.420376 9.99353 0.256282 10.2045C0.0921874 10.4155 0 10.7016 0 11C0 11.2984 0.0921874 11.5845 0.256282 11.7955C0.420376 12.0065 0.642936 12.125 0.875 12.125H20.125C20.3571 12.125 20.5796 12.0065 20.7437 11.7955C20.9078 11.5845 21 11.2984 21 11Z"
              fill="#7749F8"
            />
            <path
              d="M10.5 0.5C10.2016 0.5 9.91548 0.592187 9.7045 0.756281C9.49353 0.920375 9.375 1.14294 9.375 1.375L9.375 20.625C9.375 20.8571 9.49353 21.0796 9.7045 21.2437C9.91548 21.4078 10.2016 21.5 10.5 21.5C10.7984 21.5 11.0845 21.4078 11.2955 21.2437C11.5065 21.0796 11.625 20.8571 11.625 20.625L11.625 1.375C11.625 1.14294 11.5065 0.920375 11.2955 0.756281C11.0845 0.592187 10.7984 0.5 10.5 0.5Z"
              fill="#7749F8"
            />
          </svg>
        </div>

        <div className="flag_questions">
          <div className="flag_text">
            <svg
              width="22"
              height="28"
              viewBox="0 0 22 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 3.87613C2.61205e-05 3.69132 0.0365213 3.50838 0.10735 3.338C0.17818 3.16762 0.28192 3.01323 0.4125 2.88386C1.91104 1.39913 3.91304 0.547356 6.01103 0.501916C8.10901 0.456476 10.1453 1.22078 11.7054 2.63927L12.188 3.0951C13.191 3.98328 14.479 4.47292 15.8125 4.47292C17.146 4.47292 18.434 3.98328 19.437 3.0951L19.7794 2.77964C20.6181 2.1084 21.879 2.64483 21.9931 3.71909L22 3.87613V16.3837C22 16.5685 21.9635 16.7515 21.8926 16.9218C21.8218 17.0922 21.7181 17.2466 21.5875 17.376C20.089 18.8607 18.087 19.7125 15.989 19.7579C13.891 19.8034 11.8547 19.039 10.2946 17.6206L9.812 17.1647C8.84061 16.3045 7.60068 15.8172 6.30949 15.7882C5.0183 15.7593 3.7582 16.1905 2.75 17.0063V26.1118C2.74961 26.466 2.61542 26.8067 2.37483 27.0643C2.13425 27.3219 1.80544 27.4769 1.45558 27.4976C1.10572 27.5184 0.761229 27.4033 0.492481 27.176C0.223732 26.9486 0.0510175 26.6261 0.00962496 26.2744L0 26.1118V3.87613Z"
                fill="#7749F8"
              />
            </svg>
            <h1>Flags Questions</h1>
          </div>

          <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-3.9226e-07 1.88936L1.33853 0.5L8.62916 8.07201C8.74668 8.19334 8.83995 8.33762 8.90359 8.49655C8.96724 8.65547 9 8.8259 9 8.99803C9 9.17017 8.96724 9.3406 8.90359 9.49952C8.83995 9.65845 8.74668 9.80272 8.62916 9.92405L1.33853 17.5L0.00126109 16.1106L6.84403 9L-3.9226e-07 1.88936Z" fill="#7749F8"/>
           </svg>
        </div>

        <div className="questions">
            <div className="top_frame">
                <div className="quest_group">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6724 0.333984H10.2342V4.21889C10.2342 4.49184 9.88841 4.62679 9.62053 4.2841L8.67057 3.07101L7.72062 4.2841C7.45083 4.62528 7.10695 4.49184 7.10695 4.21889V0.333984H2.28498C1.11934 0.336486 0.176086 1.09137 0.176086 2.02169V11.9842C0.179221 12.9127 1.12158 13.6648 2.28498 13.6673H11.6743C11.9311 13.667 12.1772 13.5852 12.3583 13.4399C12.5404 13.2953 12.6429 13.0989 12.6433 12.894V1.10884C12.6422 0.68123 12.2082 0.334818 11.6724 0.333984ZM11.8529 11.6657L2.69156 11.6748C2.49188 11.6858 2.333 11.8127 2.31923 11.972C2.30398 12.1483 2.4707 12.301 2.69156 12.3132H11.8529L11.851 12.9016C11.8511 12.9366 11.8341 12.9704 11.8035 12.9956C11.7719 13.0199 11.7297 13.0336 11.6857 13.0335H2.28498C1.56359 13.0335 0.978797 12.5667 0.978797 11.991C0.978797 11.4152 1.56359 10.9485 2.28498 10.9485H11.6857C11.7776 10.9493 11.8519 11.0086 11.8529 11.0819V11.6657Z" fill="#838383"/>
</svg>

 <h1>Questions</h1>
                </div>
                <p>1/5</p>
            </div>
            <div className="bottom_isprog">
                    <div className="prog_fill"></div>
            </div>
        </div>
      </div>
        <button>SUBMIT <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.08106 12.6277L10.0819 11.2274C10.7071 11.1025 10.7071 10.8993 10.0819 10.7744L3.08106 9.37415C2.66406 9.2909 2.25793 8.8844 2.17468 8.46778L0.774434 1.4669C0.649184 0.841401 1.01781 0.550776 1.59718 0.818151L22.9606 10.678C23.3464 10.8562 23.3464 11.1457 22.9606 11.3238L1.59718 21.1837C1.01781 21.451 0.649184 21.1604 0.774434 20.5349L2.17468 13.534C2.25793 13.1174 2.66406 12.7109 3.08106 12.6277Z" fill="white"/>
</svg>
</button>
    </div>
  );
};

export default QuizRightBar;
