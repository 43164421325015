import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HomeIcon from '@mui/icons-material/Home';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import EnrollInfoPage from "../pages/enroll/enrollInfoPage";
import EnrollPage from "../pages/enroll/enrollPage";
import EnrollRAGPage from "../pages/enroll/EnrollRAGPage";
import HomePage from "../pages/home/HomePage";
import AllClasses from "../pages/myclasses/AllClasses";
import Analytics from "../pages/myclasses/Analytics";
import FlagQuestions from "../pages/myclasses/FlagQuestions";
import ClassOne from "../pages/myclasses/ClassOne";
import ClassTwo from "../pages/myclasses/ClassTwo";
import Learn from '../pages/myclasses/Learn';
import MyClassesPageLayout from "../pages/myclasses/MyClassesPage";
import QuestionCardPage from "../pages/Questions/QuestionCardPage";
import SettingsPage from "../pages/settings/SettingsPage";
import { RouteType } from "./config";
import TopicSelector from '../pages/myclasses/TopicSelector';
import PDP from '../pages/myclasses/PDP';
import UpcomingExams from '../pages/myclasses/UpcomingExams';


const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  {
    path: "/home",
    element: <HomePage />,
    state: "home",
    sidebarProps: {
      displayText: "Home",
      icon: <HomeIcon />
    }
  },
  {
    path: "/myclasses",
    element: <MyClassesPageLayout />,
    state: "myclasses",
    sidebarProps: {
      displayText: "My Classes",
      icon: <AutoStoriesIcon />
    },
    child: [
      {
        path: "/myclasses/All-Classes",
        element: <AllClasses />,
        state: "myclasses.default",
        sidebarProps: {
          displayText: "All Classes"
        },
      },

      {
        path: "/myclasses/Compuer-Science",
        element: <ClassOne />,
        state: "myclasses.One",
        sidebarProps: {
          displayText: "Computer Science"
        },
      },
      {
        path: "/myclasses/History",
        element: <ClassTwo />,
        state: "myclasses.Two",
        sidebarProps: {
          displayText: "History"
        }
      }, {
        path: "/myclasses/learn/:topic?/:subTopic?/:contentName?/:origClassId?",
        element: <Learn />,
        state: "myclasses.learn",
      },

    ],
  },
  {
    path: "/enroll",
    element: <EnrollPage />,
    state: "enroll",
    sidebarProps: {
      displayText: "Enroll in new Classes",
      icon: <LibraryAddIcon />
    }
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsIcon />
    }
  },
  {
    path: "/enroll/subject-information/:id",
    element: <EnrollInfoPage />,
    state: "enroll.info",
  },
  {
    path: "/enroll/RAG/:id",
    element: <EnrollRAGPage />,
    state: "enroll.RAG",
  },
  {
    path: "/Class/Questions/:topic?/:subTopic?/:contentName?/:origClassId?/:isExam?",
    element: <QuestionCardPage />,
    state: "class.Questions",
  },
  {
    path: "/Class/Exam/:topic?/:subTopic?/:contentName?/:origClassId?/:examId?",
    element: <QuestionCardPage />,
    state: "class.Questions",
  },
  {
    path: "/Class/Topics/:origClassId",
    element: <TopicSelector />,
    state: "topics.default",
  },
  {
    path: "/Class/Analytics/:origClassId",
    element: <Analytics />,
    state: "topics.default",
  },
  {
    path: "/Class/FlagQuestions/:origClassId",
    element: <FlagQuestions />,
    state: "topics.default",
  },
  {
    path: "/Class/upcomingExams/:origClassId",
    element: <UpcomingExams />,
    state: "exams.default",
  },
  {
    path: "/Class/PDP/:topic?/:origClassId?",
    element: <PDP />,
    state: "topics.default",

  }
];

export default appRoutes;