import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [selectedButton, setSelectedButton] = useState("dashboard"); // Initialize with the default selected button
  const navigate = useNavigate();

  // Function to handle button click
  const handleButtonClick = (route: string, buttonName: string) => {
    setSelectedButton(buttonName); // Update the selected button state
    navigate(route); // Navigate to the selected route
  };
  
  // Function to determine if the button is selected
  const getButtonClass = (buttonName: string) => {
    return buttonName === selectedButton ? "nav_button selected_nav_button" : "nav_button";
  };
  
  return (
    <div className="navBar">
      <div className="top_navbar">
        <img src={"https://i.imgur.com/EIMiHvF.png"} alt="Logo" />
        <div className="search_bar_cont">
          <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="4.3" stroke="#7749F8" strokeWidth="1.4" />
            <line
              x1="10.0101"
              y1="11"
              x2="8"
              y2="8.98995"
              stroke="#7749F8"
              strokeWidth="1.4"
              strokeLinecap="round"
            />
          </svg>
          <input type="text" placeholder="Search course" />
        </div>
        <div className="first_top_group">
          <h1>Overview</h1>
          <div className="cont_first_top_group">
            <button
              id="dashboard"
              className={getButtonClass("dashboard")}
              onClick={() => handleButtonClick("/home", "dashboard")}
            >
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="#7749F8"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.6686 0.59875C10.2886 0.25875 9.70863 0.25875 9.32863 0.59875L0.968633 8.12875C0.628633 8.42875 0.838633 8.99875 1.29863 8.99875H2.99863V15.9987C2.99863 16.5487 3.44863 16.9988 3.99863 16.9988H6.99863C7.54863 16.9988 7.99863 16.5487 7.99863 15.9987V10.9987H11.9986V15.9987C11.9986 16.5487 12.4486 16.9988 12.9986 16.9988H15.9986C16.5486 16.9988 16.9986 16.5487 16.9986 15.9987V8.99875H18.6986C19.1586 8.99875 19.3786 8.42875 19.0286 8.12875L10.6686 0.59875Z" />
              </svg>
              Dashboard
            </button>
            <button className={getButtonClass("myClasses")} onClick={() => handleButtonClick("/myclasses/All-Classes", "myClasses")}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_53_337)">
                  <path
                    d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                    fill="#7749F8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_53_337">
                    <rect width="24" height="24" fill="#7749F8" />
                  </clipPath>
                </defs>
              </svg>
              My Classes
            </button>
            <button className={getButtonClass("enroll")} onClick={() => handleButtonClick("/enroll", "enroll")}>
              <svg
                width="19"
                height="17"
                viewBox="0 0 19 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.85 0C1.27599 0 0 1.34315 0 3V13C0 14.6569 1.27599 16 2.85 16H5.72502C6.33767 16 6.94631 16.104 7.52752 16.3079L9.16891 16.8838C9.38323 16.959 9.61677 16.959 9.83109 16.8838L11.4725 16.3079C12.0537 16.104 12.6623 16 13.275 16H16.15C17.724 16 19 14.6569 19 13V3C19 1.34315 17.724 0 16.15 0H12.35C11.3194 0 10.3846 0.4319 9.70001 1.13314C9.59138 1.24442 9.40862 1.24442 9.29999 1.13314C8.61545 0.4319 7.68064 0 6.65 0H2.85ZM10.45 14.5585L10.8717 14.4105C11.6466 14.1386 12.4581 14 13.275 14H16.15C16.6747 14 17.1 13.5523 17.1 13V3C17.1 2.44772 16.6747 2 16.15 2H12.35C11.3007 2 10.45 2.89543 10.45 4V14.5585Z"
                  fill="#7749F8"
                />
              </svg>
              Enroll in new class
            </button>
          </div>
        </div>
        <div className="div_bar"></div>
        <div className="first_top_group">
          <h2>PROFILE</h2>
          <div className="cont_first_top_group">
          <button className={getButtonClass("settings")} onClick={() => handleButtonClick("/settings", "settings")}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.51653 2.29207C10.2129 1.21615 11.7871 1.21615 12.4835 2.29207L13.1162 3.2697C13.5148 3.88555 14.2524 4.19109 14.9698 4.03746L16.1085 3.79359C17.3617 3.5252 18.4748 4.63835 18.2064 5.89154L17.9625 7.03025C17.8089 7.74756 18.1145 8.48519 18.7303 8.88378L19.7079 9.51653C20.7839 10.2129 20.7839 11.7871 19.7079 12.4835L18.7303 13.1162C18.1145 13.5148 17.8089 14.2524 17.9625 14.9698L18.2064 16.1085C18.4748 17.3617 17.3617 18.4748 16.1085 18.2064L14.9698 17.9625C14.2524 17.8089 13.5148 18.1145 13.1162 18.7303L12.4835 19.7079C11.7871 20.7839 10.2129 20.7839 9.51653 19.7079L8.88378 18.7303C8.48519 18.1145 7.74756 17.8089 7.03025 17.9625L5.89154 18.2064C4.63835 18.4748 3.5252 17.3617 3.79359 16.1085L4.03746 14.9698C4.19109 14.2524 3.88555 13.5148 3.26971 13.1162L2.29207 12.4835C1.21615 11.7871 1.21615 10.2129 2.29207 9.51653L3.2697 8.88378C3.88555 8.48519 4.19109 7.74756 4.03746 7.03025L3.79359 5.89154C3.5252 4.63835 4.63835 3.5252 5.89154 3.79359L7.03025 4.03746C7.74756 4.19109 8.48519 3.88555 8.88378 3.26971L9.51653 2.29207Z"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                />
                <circle
                  cx="11"
                  cy="11"
                  r="3"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                />
              </svg>
              Settings
            </button>
            <button>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="2"
                  width="18"
                  height="17"
                  rx="2"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                />
                <path
                  d="M5 3V1"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15 3V1"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 7H19"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 11L16 11"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 15L16 15"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Calendar
            </button>
            <button>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="9"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                />
                <path
                  d="M8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7C12 7.93374 11.3601 8.71803 10.4949 8.93829C10.2273 9.00641 10 9.22386 10 9.5V11"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 14V15"
                  stroke="#B4B4B4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              Help
            </button>
          </div>
        </div>
      </div>
      <div className="bottom_navbar">
        <button>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M0 3.5576C0 1.5968 1.62419 0 3.61963 0H7.58851C9.57988 0 11.2 1.592 11.2 3.5496V12.4424C11.2 14.404 9.57581 16 7.57956 16H3.6123C1.62012 16 0 14.408 0 12.4504V11.6984V3.5576Z"
              fill="#7749F8"
            />
            <path
              d="M15.8238 7.56169L13.5471 5.23449C13.3118 4.99449 12.9332 4.99449 12.6987 5.23609C12.4649 5.47769 12.4657 5.86729 12.7002 6.10729L13.9476 7.38169H12.7516H6.03939C5.70825 7.38169 5.43945 7.65769 5.43945 7.99769C5.43945 8.33849 5.70825 8.61369 6.03939 8.61369H13.9476L12.7002 9.88809C12.4657 10.1281 12.4649 10.5177 12.6987 10.7593C12.8163 10.8801 12.9698 10.9409 13.1241 10.9409C13.2768 10.9409 13.4303 10.8801 13.5471 10.7609L15.8238 8.43449C15.9367 8.31849 16.0006 8.16169 16.0006 7.99769C16.0006 7.83449 15.9367 7.67769 15.8238 7.56169"
              fill="#7749F8"
            />
          </svg>
          Logout
        </button>
      </div>
    </div>
  );
};
export default Sidebar;
