import { Box, CircularProgress, Container,Button } from "@mui/material";
import Markdown from "react-markdown";
import { useLocation, useParams } from "react-router-dom";
import TopicSubtopicContentSelector from "../../components/shared/TopicSubtopicContentSelector";
import { Topic } from "../../interfaces/module";
import { RevisionData } from "../../interfaces/Revision";
import { useClassRevisionContent } from "../../services/revision";
import { useCallback, useEffect } from "react";
import makePowerpointPresentation from "../../services/presentation";



const Learn: React.FC = () => {


  const { topic, subTopic, contentName, origClassId } = useParams()

  const { mutate: revisionContent, isPending, isError, data, error } = useClassRevisionContent();

  const location = useLocation();

  const { topics, originalClassId }: { topics: Topic[], originalClassId: string } = location.state || [];


  const triggerApi = useCallback(async ({ topic, subTopic, content, originalClassId }: { topic: string, subTopic: string, content: string, originalClassId: string }) => {
    const revision: RevisionData = {
      contentName: content,
      originalClassId: originalClassId,
      topic: topic,
      subTopic: subTopic,
    }
    revisionContent(revision);
  }, [revisionContent])



  useEffect(() => {

    if (contentName && subTopic && topic)
      triggerApi({ topic: topic!, subTopic: subTopic!, content: contentName!, originalClassId: origClassId! })

  }, [contentName, origClassId, subTopic, topic, triggerApi])
  return <Container>

    {!(topic || subTopic || contentName) &&<>
     
      <TopicSubtopicContentSelector topicList={topics} onSelectionChange={triggerApi} originalClassId={originalClassId} />
      </>}
    {isPending && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <CircularProgress />
    </Box>}
    {isError && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      Error while loading the revision content : {error.message}
    </Box>}
    {data?.slides&&<Button onClick={()=>makePowerpointPresentation(data.slides)}>Download powerpoint prezentation</Button>}
      <Markdown>{data?.summerise}</Markdown>
  </Container>
}

export default Learn;
